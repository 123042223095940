import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { InputMask } from "primereact/inputmask";
import { hideLoader, showLoader } from "../../../redux/mainSlice";
import { useDispatch } from "react-redux";
import VivToast from "../../../shared/VivitechToast";
import httpClient from "../../../_util/api";

function Payment({ visible, setVisible, orderinfo, selectedToken }) {
  const dispatch = useDispatch();
  const toast_Ref = useRef(null);
  const [card, setCard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");
  const [phone, setPhone] = useState();
  const [form, setForm] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
  });
  const closeModal = () => {
    setVisible(false);
  };
  useEffect(() => {
    const initializeSquarePaymentForm = async () => {
      if (!window.Square) {
        toast_Ref.current?.showMessage(
          "error",
          "Square payments SDK failed to load",
          "",
          "i-notify"
        );
        setTimeout(() => {
          closeModal();
        }, 1000);
        return;
      }
      const payments = window.Square.payments(
        orderinfo?.square_application_id,
        orderinfo?.square_location_id
      );

      try {
        const card = await payments.card();
        await card.attach("#card-container");
        setCard(card);
        setLoading(false);
      } catch (error) {
        toast_Ref.current?.showMessage(
          "error",
          "Unable to connect to square payment",
          "",
          "i-notify"
        );
        setLoading(false);
        setTimeout(() => {
          closeModal();
        }, 3000);
      }
    };
    if (visible) {
      initializeSquarePaymentForm();
    }
  }, [visible]);
  const handlePayment = async () => {
    if (!card) {
      toast_Ref.current?.showMessage(
        "error",
        "Unable to connect to square payment",
        "",
        "i-notify"
      );
      return;
    }

    const errors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!form.email || !emailRegex.test(form.email)) {
      errors.email = "A valid email is required";
    }

    // Phone number validation
    const phoneRegex = /^\d{10}$/;
    if (!form.phone || !phoneRegex.test(form.phone)) {
      errors.phone = "A valid phone number is required";
    }

    // First name validation
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!form.firstName) {
      errors.firstName = "First name is required";
    } else if (!nameRegex.test(form.firstName)) {
      errors.firstName = "First name must contain only letters";
    }

    // Last name validation
    if (!form.lastName) {
      errors.lastName = "Last name is required";
    } else if (!nameRegex.test(form.lastName)) {
      errors.lastName = "Last name must contain only letters";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setValidationErrors({
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
      });
    }

    try {
      dispatch(showLoader());
      // Request a card nonce (token)
      const result = await card.tokenize();
      if (result.status === "OK") {
        const nonce = result;
        // Set the form values
        setForm({
          ...form,
          selectedCountryCode: selectedCountryCode,
        });
        // Send the nonce to the backend for processing
        await processPayment(nonce?.token);
      } else {
        toast_Ref.current?.showMessage(
          "error",
          result?.errors.length>0 ? "Please fill card details for payment" : result?.errors,
          "",
          "i-notify"
        );
        dispatch(hideLoader());
      }
    } catch (error) {
      console.error("Payment failed:", error);
      dispatch(hideLoader());
    }
  };
  const processPayment = async (nonce) => {
    try {
      const response = await httpClient.post(`/process-payment`, {
        nonce,
        orderid: orderinfo?.order_id,
        location_id: orderinfo?.square_location_id,
        selectedToken: selectedToken,
        customerInfo: form,
      });

      if (response && response.status === 200) {
        dispatch(hideLoader());
        toast_Ref.current?.showMessage(
          "success",
          response?.data?.message,
          "",
          "i-chk-circle"
        );
        setTimeout(() => {
          closeModal();
        }, 1000);
      } else {
        toast_Ref.current?.showMessage(
          "error",
          error?.response?.data?.message ||
            "An error occurred while fetching user data",
          "",
          "i-notify"
        );
        setValidationErrors({
          email: error?.response?.data?.error?.email_address || '',
          phone: error?.response?.data?.error?.phone_number || '',
          firstName: "",
          lastName: "",
        });
      }
    } catch (error) {
      toast_Ref.current?.showMessage(
        "error",
        error?.response?.data?.message || "Error processing payment",
        "",
        "i-notify"
      );
    }
    dispatch(hideLoader());
  };

  function removeSpecialCharacters(value) {
    return value.replace(/[^\d]/g, ""); // Replace all non-numeric characters
  }

  const setPhoneValue = (e) => {
    setPhone(e.target.value);
    setForm({ ...form, phone: removeSpecialCharacters(e.target.value) });
  };

  return (
    <>
      <VivToast ref={toast_Ref} />
      <div className="w-full flex justify-content-center align-items-center ">
        <Dialog
          id={"mydialog"}
          className="datefilter-modal"
          header={""}
          style={{ width: "40vw" }}
          visible={visible}
          modal={true}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          onHide={closeModal}
          closable
          closeOnEscape
          dismissableMask
          draggable={false}
        >
          <div className="flex flex-column align-items-center align-items-center zoom-out">
            <div
              className="w-full pb-5"
              style={{
                borderBottom: "2px solid #E2E2E2",
              }}
            >
              <p
                className="text-center pbcont-child-sub-h1"
                style={{ fontWeight: "600" }}
              >
                {selectedToken?.item_name}
              </p>
              <p className="text-center pbcont-child-sub-h1">
                {selectedToken?.item_variation_amount_currency} $
                {selectedToken?.item_variation_amount}
              </p>
            </div>
            <div className="w-full">
              {/* <p className='pbcont-child-sub-p2 text-left pb-3 pt-3'>Order Summary (1 item)</p> */}
              <Panel
                header="Order Summary (1 item)"
                toggleable
                collapsed={true}
              >
                <div className="w-full flex flex-column">
                  <div className="flex flex-row justify-content-between">
                    <p className="pbcont-child-sub-p2 text-left pb-2">
                      {selectedToken?.item_name}
                    </p>
                    <p className="pbcont-child-sub-p2 text-right pb-2">
                      {selectedToken?.item_variation_amount_currency} $
                      {selectedToken?.item_variation_amount}
                    </p>
                  </div>
                  <p className="pbcont-child-sub-p2 text-left pb-2">
                    ${selectedToken?.item_variation_amount}
                  </p>
                </div>
              </Panel>
            </div>
            <div className="w-full total-container">
              <div className="flex flex-row justify-content-between">
                <p className="w-full pbcont-child-sub-p2 text-left pb-3">
                  Subtotal
                </p>
                <p className="w-full pbcont-child-sub-p2 text-right pb-3">
                  {selectedToken?.item_variation_amount_currency} $
                  {selectedToken?.item_variation_amount}
                </p>
              </div>
              <div className="flex flex-row justify-content-between">
                <p className="w-full pbcont-child-sub-p3 text-left pb-2">
                  Order total
                </p>
                <p className="w-full pbcont-child-sub-p3 text-right pb-2">
                  {selectedToken?.item_variation_amount_currency} $
                  {selectedToken?.item_variation_amount}
                </p>
              </div>
            </div>
            <p className="w-full pbcont-child-sub-p2 text-left pb-3">CONTACT</p>
            <div className="flex flex-row w-full justify-content-between">
              <Dropdown
                style={{ width: "18dvw" }}
                optionLabel="name"
                optionValue="id"
                placeholder="Country Code"
                options={[
                  { name: "+1 United States", id: "+1" },
                  { name: "+52 Mexico", id: "+52" },
                ]}
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.value)}
              />
              <div className="flex flex-column">
                <InputMask
                  className="mask-container"
                  id="phone"
                  mask="(999) 999-9999"
                  placeholder="Phone number"
                  value={phone}
                  onChange={(e) => setPhoneValue(e)}
                  disabled={loading}
                  autoClear={false}
                />
                {validationErrors.phone && (
                  <small className="text-danger">
                    {validationErrors.phone}
                  </small>
                )}
              </div>
            </div>
            <div className="w-full pt-3 pb-3 flex flex-column">
              <InputText
                placeholder="Email address for receipt"
                value={form.email}
                disabled={loading}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
              {validationErrors.email && (
                <small className="text-danger">{validationErrors.email}</small>
              )}
            </div>
            <div className="w-full flex flex-row justify-content-between pb-3">
              <div style={{ width: "18dvw" }}>
                <InputText
                  placeholder="First Name"
                  value={form.firstName}
                  disabled={loading}
                  onChange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                />
                {validationErrors.firstName && (
                  <small className="text-danger">
                    {validationErrors.firstName}
                  </small>
                )}
              </div>
              <div style={{ width: "18dvw" }}>
                <InputText
                  placeholder="Last Name"
                  value={form.lastName}
                  disabled={loading}
                  onChange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
                {validationErrors.lastName && (
                  <small className="text-danger">
                    {validationErrors.lastName}
                  </small>
                )}
              </div>
            </div>
            <div className="w-full payment-footer pt-3">
              <p className="w-full pbcont-child-sub-p2 text-left pb-1">
                PAYMENT
              </p>
              <p className="w-full pbcont-child-sub-p3 text-left pb-3">
                All transactions are secured and encryted
              </p>
            </div>
            <div className="w-full">
              {/* Square Payment Form */}
              <div id="card-container"></div>
              <div className="w-full flex justify-content-center">
                <button
                  className="pay-button"
                  onClick={handlePayment}
                  disabled={loading}
                >
                  {loading ? "Loading Payment Form..." : "Pay Now"}
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
}
export default Payment;
